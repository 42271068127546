import React, { Component } from "react"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"
//images
import CareersBackground from "../components/images/AboutBackground"
import AboutPageImage from "../components/images/AboutPageImage"
//mui
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const styles = theme => ({})

export class AboutPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="About" keywords={[`adc`, `American Dream Construction`]} />
        <div className="hero-image">
          <div className="wrap">
            <h1 className="hero-text  ">
              BUILDING THE FUTURE
              <br />
              RENOVATING THE PAST
            </h1>
            <h2 className="hero-text"> </h2>
            <CareersBackground />
          </div>
        </div>
        <div className="container-body">
          <div className="container-centered">
            <Typography variant="h5" gutterBottom className="container-margin">
              {" "}
              CONSTRUCTION PROFESSIONALS
            </Typography>
            <Typography variant="paragraph">
              Established in 1997, American Dream Construction has become an
              industry leader, excelling in industrial steel roofing & siding,
              renovations, new constructions, remodels,and commercial buildings
              all over Alaska. <br /> <br /> While quality and unmatched
              craftsmanship have become synonymous with the perception of
              American Dream Construction within their community and industry,
              the company has and continues to distinguish itself in other ways.
              Among these is one of the company’s most valued assets: its
              talented, professional, and highly trained staff.
            </Typography>
            <Typography variant="h5" gutterBottom className="container-margin">
              {" "}
              WHAT WE DO
            </Typography>
            {/* <Grid container spacing={32} style={{ marginBottom: "30px" }}>
              <Grid item xs={12} sm={6}> */}
            <Typography variant="paragraph">
              {" "}
              <ul>
                <li> Custom homes</li>
                <li> Commercial Construction </li>
                <li> Renovations and Remodels </li>
                <li> Tenant finishes for commercial clients </li>
                <li> And lots more... let's talk!</li>
              </ul>{" "}
            </Typography>
            {/* </Grid> */}
            {/* <Grid item xs={12} sm={6}>
                <div className="clip-circle">
                  {" "}
                  <AboutPageImage />
                </div>
              </Grid> */}
            {/* </Grid> */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles, { withTheme: true })(AboutPage)
